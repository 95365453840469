export function initBackButton() {

    const backButtons = $('[data-back-button]');

    if (backButtons.length > 0) {
        backButtons.click(function (e) {
            e.preventDefault();

            let fallbackPath = $(e.currentTarget).attr('data-back-button');

            if (window.history.length > 2) {
                window.history.back();

                window.setTimeout(() => {
                    redirectToFallbackPath(fallbackPath);
                }, 1000);
            } else {
                redirectToFallbackPath(fallbackPath);
            }
        });
    }

    function redirectToFallbackPath(fallbackPath: any) {
        if (fallbackPath && fallbackPath != 'NONE') {
            window.history.replaceState(null, '', fallbackPath);
        } else {
            window.history.replaceState(null, '', '/');
        }

        window.location.reload();
    }
}