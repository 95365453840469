import * as $ from "jquery";
import * as moment from "moment";
import "bootstrap";
import "tempusdominus-bootstrap-4";

import "../scss/main.scss";

import {initTables} from "./tables";
import {updateTenantUser} from "./tenant";
import {initLockStateChange} from "./lock";
import {initBackButton} from "./back";

(<any>window).$ = $;

// when DOM is ready
$(() => {
    console.log('DOM is ready');

    // initialize datetimepicker
    ($('.datetimepicker') as any).datetimepicker({
        format: 'DD/MM/YYYY HH:mm',
        extraFormats: [ moment.ISO_8601 ],
    });

    updateTenantUser();
    $('#role').change(function() {
        updateTenantUser();
    });
    // call function in separate file
    initTables();

    // form validation
    $('form.needs-validation').on('submit', function(e:Event) {
        let form = this as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        $(form).addClass('was-validated');
    });

    // install lock state change
    initLockStateChange();

    // init back button behavior
    initBackButton();
});

