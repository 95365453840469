import * as $ from "jquery";

export function updateTenantUser() {

    if ($('#role').val() == 'TenantUser' || $('#role').val() == 'TenantAdmin') {
        $('#select-tenant').removeAttr('style');
        $('#tenant').prop('required', true);
    } else {
        $('#select-tenant').attr('style', 'display: none;');
        $('#tenant').removeAttr('required');
        $('#tenant').prop('required', false);
    }

}